<template>
  <div class="page-wrapper">
    <Header />
    <div class="settings">
      <button @click="$router.back()" class="settings__back-link btn-reset">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Back to profile settings"
        />
      </button>
      <div class="settings__container container container--big">
        <div class="settings__top">
          <div class="settings__top-title">FAQs</div>
        </div>
      </div>
      <div class="settings__privacy-policy">
        <div class="faq__content">
          <a-collapse
            v-model:activeKey="activeKey"
            expand-icon-position="right"
          >
            <template #expandIcon="{ isActive }">
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ rotate: isActive }" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.994 6.786L2.044.83a1.12 1.12 0 00-1.589 0 1.13 1.13 0 000 1.593l6.742 6.75c.426.427 1.11.436 1.55.033l6.79-6.778c.22-.22.327-.511.327-.797 0-.286-.107-.576-.328-.797a1.12 1.12 0 00-1.588 0L7.994 6.786z" fill="#fff"/></svg>
            </template>
            <a-collapse-panel
              v-for="(q, i) in questions"
              :key="i"
              :header="q.name"
            >
              <p>{{ q.answer }}</p>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CheckoutFAQ',
  components: { Header, Footer },
  data() {
    return {
      activeKey: [],
    }
  },
  computed: {
    ...mapState('faq', ['questions']),
  },
  created() {
    this.getFAQs()
  },
  methods: {
    ...mapActions('faq', ['getFAQs']),
  },
}
</script>

<style lang="scss" scoped>
.settings {
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 25px;
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    padding-bottom: 36px;

    @media (max-width: 991px) {
      padding-bottom: 24px;
    }

    @media (max-width: 991px) {
      padding-bottom: 36px;
    }
  }

  &__privacy-policy-wrapper {
    max-width: 848px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow: auto;

    @media (max-width: 991px) {
      padding: 0 24px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    margin: 16px 0;

    b {
      font-weight: 700;
      font-weight: 400;
    }

    a {
      text-decoration: underline;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  li {
    margin-left: 10px;
    color: white;
  }

  table {
    text-align: left;
    color: white;
    overflow: auto;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid white;
    white-space: nowrap;
  }
}

.faq {
  &__content {
    max-width: 726px;
    padding: 10px;
    margin: auto;

    .ant-collapse {
      background-color: inherit;
      border: none;
      border-radius: 12px;
      display: grid;
      gap: 20px;
    }

    .ant-collapse > .ant-collapse-item {
      border: 1px solid rgba(#fff, 0.1);
      border-radius: 12px;
    }

    :deep(.ant-collapse-content) {
      background-color: inherit;
      border-top: none;
    }

    :deep(.ant-collapse-content > .ant-collapse-content-box) {
      padding: 3px 54px 16px 16px;
    }

    :deep(.ant-collapse-icon-position-right
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow.rotate) {
      transform: rotate(180deg);
    }

    :deep(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      padding: 17px 30px 17px 16px;
    }

    :deep(.ant-collapse-content > .ant-collapse-content-box > p) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }
  }
}
</style>
